import React from 'react';
import Titulo from '../layout/Titulo';
import ProjetoCard from './ProjetoCard';

// imagens
import projectMemory from '../../assets/images/projects/MemoryPotter.jpg';
import projectCalc from '../../assets/images/projects/calculadora.jpg';
import projectTerm from '../../assets/images/projects/termometro.jpg';
import projectMUC from '../../assets/images/projects/MUC.jpg';
import projectSistema from '../../assets/images/projects/sistema.jpg';
import projectRoulette from '../../assets/images/projects/Roulette.jpg';
import { NavLink } from 'react-router-dom';

const Projetos = () => {
  return (
    <section
      id="projetos"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Titulo title="Conheça alguns dos meus Projetos" desc="Projetos" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-14 pb-8">
        <ProjetoCard
          title="Memory Potter"
          desc="Projeto pessoal, de criação de um jogo da memória, de um dos meus personagens favoritos, onde foram acrescentados alguns desafios"
          src={projectMemory}
          linkGit="https://github.com/pablo-santos21/memory-game"
          linkSite="https://projeto-obliviate.netlify.app/"
        />
        <ProjetoCard
          title="Método Última Chance"
          desc="Site para apresentação, reprise da live e informações para a compra da tumas 1 do curso de relacionamentos do psicólogo Bruno Carlos."
          src={projectMUC}
          linkGit="https://github.com/pablo-santos21/metodouc"
          linkSite="https://metodouc.com.br/"
        />
        <ProjetoCard
          title="Gerenciamento de eventos"
          desc="Sistema criado em React e Node js, onde foi criado uma API para gerenciar todos os eventos que foram marcados em um salçao de festas."
          src={projectSistema}
          linkGit="https://github.com/pablo-santos21/front-controle-evento"
          linkSite="https://projeto-evento-gv-psantos.netlify.app/"
        />
        <ProjetoCard
          title="Calculadora"
          desc="Projeto criado para colocar em pratica o que foi aprendido duranto um curso realizado, ao final foi criado essa calucladora com design clean."
          src={projectCalc}
          linkGit="https://github.com/pablo-santos21/calculadora"
          linkSite="https://projeto-calc-psantos.netlify.app/"
        />
        <ProjetoCard
          title="Termômetro"
          desc="Sistema criado utilizando uma API externa, do site, openweathermap, onde mostra a temperatura do local no exato momento da requisição."
          src={projectTerm}
          linkGit="https://github.com/pablo-santos21/thermometer"
          linkSite="https://projeto-thermometer-psantos.netlify.app/"
        />
        <ProjetoCard
          title="House Bot Roullete"
          desc="Criação de site HTML e CSS para divulgação de um bot para o telegram"
          src={projectRoulette}
          linkSite="https://pablo-santos21.github.io/rollete/"
        />
      </div>

      <div className="py-20 items-center justify-center text-center">
        <NavLink
          className="text-md bg-designColor text-darkText font-bold px-6 py-4 hover:bg-[#04cf00] cursor-pointer rounded-full uppercase"
          to="/portifolio"
        >
          Veja Mais
        </NavLink>
      </div>
    </section>
  );
};

export default Projetos;
