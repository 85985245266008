// imagens
import projectMemory from '../assets/images/projects/MemoryPotter.jpg';
import projectCalc from '../assets/images/projects/calculadora.jpg';
import projectTerm from '../assets/images/projects/termometro.jpg';
// import projectLais from '../assets/images/projects/lais.jpg';
import projectRoulette from '../assets/images/projects/Roulette.jpg';
import projectMUC from '../assets/images/projects/MUC.jpg';
import projectBruno from '../assets/images/projects/Bruno.jpg';
import projectSistema from '../assets/images/projects/sistema.jpg';
import projectTragaFacil from '../assets/images/projects/tragafacil.jpg';

export const project = [
  {
    _id: 1001,
    title: 'Memory Potter',
    desc: 'Projeto pessoal, de criação de um jogo da memória, de um dos meus personagens favoritos, onde foram acrescentados alguns desafios',
    linkGit: 'https://github.com/pablo-santos21/memory-game',
    linkSite: 'https://projeto-obliviate.netlify.app/',
    src: projectMemory,
  },
  // {
  //   _id: 1002,
  //   title: 'Cerimonialista Lais Carrera',
  //   desc: 'Site criado para apresentar os serviços oferecidos por uma cerimonialista em um evento, sua equipe e feedbacks de clientes.',
  //   linkSite: 'https://laiscarrera.com.br/',
  //   src: projectLais,
  // },
  {
    _id: 1003,
    title: 'Termômetro',
    desc: 'Sistema criado utilizando uma API externa, do site, openweathermap, onde mostra a temperatura do local no exato momento da requisição.',
    linkGit: 'https://github.com/pablo-santos21/thermometer',
    linkSite: 'https://projeto-thermometer-psantos.netlify.app/',
    src: projectTerm,
  },
  {
    _id: 1004,
    title: 'Gerenciamento de eventos',
    desc: 'Sistema criado em React e Node js, onde foi criado uma API para gerenciar todos os eventos que foram marcados em um salçao de festas.',
    linkGit: 'https://github.com/pablo-santos21/front-controle-evento',
    linkSite: 'https://projeto-evento-gv-psantos.netlify.app/',
    src: projectSistema,
  },
  {
    _id: 1005,
    title: 'Calculadora',
    desc: 'Projeto criado para colocar em pratica o que foi aprendido duranto um curso realizado, ao final foi criado essa calucladora com design clean.',
    linkGit: 'https://github.com/pablo-santos21/calculadora',
    linkSite: 'https://projeto-calc-psantos.netlify.app/',
    src: projectCalc,
  },
  {
    _id: 1006,
    title: 'Psicólogo Bruno Carlos',
    desc: 'Site criado para apresentar os serviços oferecidos pelo cliente e divulgar seu canal no youtube.',
    linkSite: 'https://psicologobrunocarlos.com.br/',
    src: projectBruno,
  },
  // {
  //   _id: 1007,
  //   title: 'PabloZapp',
  //   desc: 'Sistema em criação, será uma copia do aplicativo Whatsapp web criado com socket.io.',
  //   linkGit: 'https://github.com/pablo-santos21/calculadora',
  //   linkSite: 'https://projeto-calc-psantos.netlify.app/',
  //   src: projectTwo,
  // },
  {
    _id: 1008,
    title: 'House Bot Roullete',
    desc: 'Criação de site HTML e CSS para divulgação de um bot para o telegram',
    linkGit: 'https://github.com/pablo-santos21/roulette',
    linkSite: 'https://projeto-roulette.netlify.app/',
    src: projectRoulette,
  },
  {
    _id: 1009,
    title: 'Método Última Chance',
    desc: 'Site para apresentação de um curso de relacionamentos',
    linkGit: 'https://github.com/pablo-santos21/metodouc',
    linkSite: 'https://metodouc.com.br/',
    src: projectMUC,
  },
  {
    _id: 1010,
    title: 'Traga Facil',
    desc: 'Criação do site em HTML, CSS, JS e PHP feito para ajudar pessoas a importar dos EUA',
    linkSite: 'https://tragafacil.pablosantos.dev/',
    src: projectTragaFacil,
  },
];
